:root {
  --coconut-green: #00C95C;
  --sky-blue: #00F8FF;
}

::selection {
  background-color: var(--coconut-green);
  color: #fff;
  -webkit-text-fill-color: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

html {
  background: #000;
  color: #fff;
  cursor: crosshair;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.75;
  overflow-x: hidden;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header,
section {
  animation: ease-out 0.5s;
  animation-fill-mode: both;
  margin-bottom: 2rem;
}

header {
  animation-name: slideInDown;
  animation-delay: 0.25s;
}

section {
  animation-name: slideInUp;
  animation-delay: 0.125s;
  margin-bottom: 3rem;
}

main {
  padding: 4rem 2rem 2rem 12vw;
  max-width: 40rem;
}

h1,
h2,
h3,
li,
p {
  margin-top: 0;
  padding-top: 0;
}

@keyframes mangoDreams {
  0% {
    background-position: 0vw 50%;
  }

  100% {
    background-position: 200vw 50%;
  }
}

h1 {
  animation: mangoDreams ease-in infinite 20s;
  animation-direction: alternate;
  background-image: linear-gradient(
    to right,
    var(--coconut-green),
    var(--sky-blue),
    var(--coconut-green)
  );
  background-size: 100%;
  line-height: 1.25;
  padding-bottom: 0.1em;

  /* Enable see-through text so we can see the tasty gradient background */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  font-size: 3rem;
  letter-spacing: -0.01em;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0rem;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
}

p {
  margin-bottom: 0.5rem;
}

p:last-child,
li:last-child {
  margin-bottom: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  margin-bottom: 2rem;
}

small {
  font-size: 0.85rem;
  opacity: 0.5;
}

a {
  border-bottom: 1px solid currentColor;
  color: inherit;
  display: inline-block;
  padding-bottom: 1px;
  text-decoration: none;
  margin-bottom: 4px;
}

a:hover {
  border-bottom-color: var(--sky-blue);
  border-bottom-width: 2px;
  padding-bottom: 0;
}

@keyframes popIn {
  0% {
    transform: scale(0);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes popOut {
  25% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(0);
  }
}

.emoji {
  animation: popIn ease-in-out 0.3s;
  font-family: sans-serif;
  font-size: 32px;
  line-height: 1;
  pointer-events: none;
  position: fixed;
}

@media screen and (max-width: 600px) {
  h1,
  h2 {
    animation-duration: 20s;
  }

  main {
    padding: 2rem;
  }
}
